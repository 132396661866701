import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import {
  dFlex,
  flexCenter,
  itemsCenter,
  justifyCenter,
} from "../../../common/commonStyles";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { downloadFile } from "../../../const/constFunctions";
import { AlertIconUrl } from "../../../const/imageUrl";
import useDataFetchHook from "../../../Hooks/DataFetchHook";

export default function DataManagement() {
  const [loading, setLoading] = useState(false);
  let url = process.env.REACT_APP_MANAGEMENT_STATUS;
  const [apiCall, fetchAgain] = useDataFetchHook(url);
  const [file, setFile] = useState(null);

  const backupStatus = apiCall?.res?.status?.backupStatus ?? {};
  const restoreStatus = apiCall?.res?.status?.restoreStatus ?? {};

  const handleStartBackup = async () => {
    try {
      setLoading(true);

      const backup = process.env.REACT_APP_CREATE_BACKUP;
      const config = createAxiosConfig({
        endPoint: backup,
      });
      const apiRes = await axios.request(config);
      await handleDownloadFile(apiRes.data.downloadUrl);
      toast(apiRes?.data?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const onSubmit = async (handleClose) => {
    try {
      if (!file) {
        return toast.error("File is required");
      }
      setLoading(true);
      window.onbeforeunload = function () {
        return true;
      };
      const restore = process.env.REACT_APP_START_RESTORE;
      const formData = new FormData();
      formData.append("zipFile", file);

      const config = createAxiosConfig({
        endPoint: restore,
        method: POST,
        data: formData,
      });
      axios.request(config);
      toast("Process started");
      setLoading(false);
      fetchAgain();
      if (handleClose) {
        handleClose?.();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    } finally {
      setFile(null);
      window.onbeforeunload = null;
    }
  };

  const handleDownloadFile = async (url) => {
    try {
      setLoading(true);
      let tDate = new Date()
        .toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .replaceAll("/", "-");
      const downloadFileName = `firestore-data-export-${tDate}.zip`;
      await downloadFile(url, downloadFileName);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      fetchAgain();
    }, 10 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const backupButtonDisabled = backupStatus.backupInProgress;
  const restoreButtonDisabled = restoreStatus?.restoreInProgress;
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Backup & Restore"]} />
      <Box>
        <Button
          sx={{ mb: 4 }}
          disabled={backupButtonDisabled || restoreButtonDisabled}
          variant="primary-medium"
          onClick={handleStartBackup}
        >
          Create Backup
        </Button>

        {backupStatus?.backupInProgress && (
          <Box sx={{ ...flexCenter, flexDirection: "column" }}>
            <Typography
              variant="bold"
              sx={{
                color: (theme) => theme.palette.text.main,
              }}
            >
              Backup is in progress
            </Typography>
            <br />
            <CircularProgress />
          </Box>
        )}
        {backupStatus?.backupComplete && (
          <Box sx={{ ...itemsCenter, gap: 2 }}>
            <Typography
              variant="semi-bold"
              sx={{
                color: (theme) => theme.palette.text.main,
              }}
            >
              Last Backup Date -{" "}
              {new Date(backupStatus.backupCompletedAt)?.toString()}
            </Typography>
            {backupStatus?.backupUrl && (
              <Button
                onClick={async () => {
                  await handleDownloadFile(backupStatus?.backupUrl);
                }}
                variant="primary-medium"
              >
                Download Backup
              </Button>
            )}
            <br />
          </Box>
        )}
        <hr />
        {restoreStatus?.restoreInProgress && (
          <Box sx={{ ...justifyCenter, flexDirection: "column", gap: 1 }}>
            <Typography
              variant="medium"
              sx={{
                color: (theme) => theme.palette.text.main,
              }}
            >
              Restore started at -{" "}
              {new Date(restoreStatus?.restoreStartedAt).toString()}
            </Typography>
            <Typography
              variant="medium"
              sx={{
                color: (theme) => theme.palette.text.main,
                mb: 1,
              }}
            >
              Restore in progress
            </Typography>
            {restoreStatus?.restoreStages?.map((stage, index) => (
              <>
                <Typography
                  variant="semibold"
                  sx={{
                    color: (theme) => theme.palette.text.main,
                    ...itemsCenter,
                    gap: 1,
                  }}
                >
                  {restoreStatus?.currentRestoreStage > index ? (
                    <CheckIcon />
                  ) : (
                    <CircularProgress size={"16px"} />
                  )}{" "}
                  {stage}
                </Typography>
              </>
            ))}
            <br />
          </Box>
        )}
        {restoreStatus?.restoreCompleted && (
          <Box sx={{ my: 2 }}>
            <Typography
              variant="semi-bold"
              sx={{
                color: (theme) => theme.palette.text.main,
              }}
            >
              Last Restore Completed At -{" "}
              {new Date(restoreStatus.restoreCompletedAt)?.toString()}
            </Typography>
          </Box>
        )}

        {!restoreButtonDisabled && (
          <>
            <Box
              sx={{
                ...dFlex,
                flexDirection: "column",
                gap: 1,
                width: "400px",
                mb: 2,
              }}
            >
              <Typography
                variant="medium"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Choose Zip file to restore
              </Typography>
              <Box sx={{ ...itemsCenter, gap: "10px" }}>
                <Box
                  component={"label"}
                  sx={{
                    ...flexCenter,
                    height: "35px",
                    width: "125px",
                    cursor: restoreButtonDisabled ? "not-allowed" : "pointer",
                    borderRadius: "4px",
                    color: (theme) => theme.palette.text.main,
                    backgroundColor: (theme) => theme.palette.text.lighter,
                  }}
                >
                  Choose a file
                  <input
                    type="file"
                    disabled={restoreButtonDisabled}
                    accept="application/zip"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      if (!file.name.endsWith(".zip")) {
                        toast.error("Invalid file");
                        e.target.value = null;
                        setFile(null);
                        return;
                      }
                      setFile(file);
                      e.target.value = null;
                    }}
                    style={{ display: "none" }}
                  />
                </Box>
                <Typography
                  variant="regular"
                  // contentEditable="true"
                  title={file ? file?.name : "No File Chosen"}
                  sx={{
                    color: (theme) => theme.palette.text.main,
                  }}
                >
                  {file ? file?.name : "No File Chosen"}
                </Typography>
              </Box>
            </Box>
            <ConformationDialog
              onSubmit={onSubmit}
              loading={loading}
              restoreButtonDisabled={
                backupButtonDisabled || restoreButtonDisabled || loading
              }
            />
          </>
        )}
      </Box>
      {loading && (
        <Box sx={{ ...flexCenter, flexDirection: "column" }}>
          <Typography
            variant="bold"
            sx={{
              color: (theme) => theme.palette.text.main,
            }}
          >
            Please wait
          </Typography>
          <br />
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

function ConformationDialog({ onSubmit, loading, restoreButtonDisabled }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{ ml: 1 }}
        disabled={restoreButtonDisabled}
        variant="primary-medium"
        onClick={handleOpen}
      >
        Restore
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "400px", pb: "20px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent sx={{ textAlign: "center" }}>
            <DialogContentText id="alert-dialog-description">
              <Box
                sx={{
                  ...itemsCenter,
                  ...flexCenter,
                  mb: 2,
                }}
              >
                <img
                  src={AlertIconUrl}
                  style={{ height: "85px", width: "85px" }}
                />
              </Box>
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to restore?
              </Typography>
              <br />
              <Typography
                variant="regular"
                sx={{
                  color: "red",
                }}
              >
                Note: This action is irreversible
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              disabled={restoreButtonDisabled}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={restoreButtonDisabled}
              onClick={() => onSubmit(handleClose)}
              variant="primary"
            >
              Start Restore
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
