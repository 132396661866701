export const checkSize = (file) => {
  return (file.size / 1024 / 1024).toFixed(2);
};
export function formatTime(time) {
  if (isNaN(time)) return "--:--";

  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
}

export const normalize = (value, max) => ((value - 0) * 100) / (max - 0);

export const getFormattedDate = (date) => {
  const currentDate = date ? new Date(date) : new Date();
  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    currentDate
  );
  return formattedDate;
};

export const convertToBase64File = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
};

export const getFileDuration = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const audio = new Audio();
      audio.src = URL.createObjectURL(file);
      audio.onloadedmetadata = () => {
        let duration = Math.floor(audio.duration / 60);
        resolve(duration);
        // You can use the duration value as needed
      };

      audio.onerror = (error) => {
        reject(error);
      };
    } catch (e) {
      reject(e);
    }
  });
};

export const reactSelectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    text: "#000",
    primary25: "#F5F5F5",
    primary50: "#B5B5B5",
    primary: "#358B8A",
  },
});

export function getLastTenWeeks() {
  const today = new Date();
  const lastTenWeeks = [];

  for (let i = 0; i < 12; i++) {
    const weekStart = new Date();
    weekStart.setDate(today.getDate() - i * 7);
    weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1); // Set to Monday
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekStart.getDate() + 6);

    const weekStartFormatted = weekStart.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    const weekEndFormatted = weekEnd.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });

    const weekInfo = {
      label: `${weekStartFormatted} - ${weekEndFormatted}`,
      value: `${weekStart.toDateString()} - ${weekEnd.toDateString()}`,
    };
    lastTenWeeks.push(weekInfo);
  }

  return lastTenWeeks;
}

export function formatNumberWithOrdinal(number) {
  if (number >= 11 && number <= 13) {
    return number + "th";
  }

  const lastDigit = number % 10;
  switch (lastDigit) {
    case 1:
      return number + "st";
    case 2:
      return number + "nd";
    case 3:
      return number + "rd";
    default:
      return number + "th";
  }
}

export function convertToAMPMFormat(timeString) {
  const [hours, minutes] = timeString.split(":");
  let period = "AM";

  let numericHours = parseInt(hours, 10);
  if (numericHours === 0) {
    numericHours = 12;
  } else if (numericHours >= 12) {
    period = "PM";
    if (numericHours > 12) {
      numericHours -= 12;
    }
  }

  const ampmTime = `${numericHours}:${minutes} ${period}`;
  return ampmTime;
}

export function getFormattedDateForChat(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const now = new Date();
  const isToday =
    now.getDate() === date.getDate() &&
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear();
  const isYesterday =
    now.getDate() === date.getDate() + 1 &&
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear();

  if (isToday) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    let formattedTime = `${
      hours === 0 ? 12 : hours > 12 ? hours - 12 : hours
    }:${minutes.toString().padStart(2, "0")} ${ampm}`;
    return formattedTime;
  } else if (isYesterday) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    let formattedTime = `Yesterday ${
      hours === 0 ? 12 : hours > 12 ? hours - 12 : hours
    }:${minutes.toString().padStart(2, "0")} ${ampm}`;
    return formattedTime;
  } else {
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    let formattedDate = `${month} ${day} ${
      hours === 0 ? 12 : hours > 12 ? hours - 12 : hours
    }:${minutes.toString().padStart(2, "0")} ${ampm}`;
    return formattedDate;
  }
}
export const AccordionTransition = {
  mountOnEnter: true,
  // unmountOnExit: true,
};

export function downloadFile(url, customFileName = "download") {
  // Fetch the URL
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // Create an <a> element and set its attributes
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = customFileName; // Set the custom file name

      // Trigger a click event to initiate the download
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(a.href);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
}
