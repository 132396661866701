import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import useKeyboardShortcut from "../../Hooks/KeyboardShortcut";
import classes from "../../common/SideBarClasses.module.css";
import {
  flexBetweenWithItemsCenter,
  menuItemsCSS,
  sideNavbarLink,
  textEllipses,
} from "../../common/commonStyles";
import {
  CertificateIcon,
  ChangeAdminIcon,
  ContentsIcon,
  DashboardIcon,
  DataManagement,
  DemographicDataIcon,
  LogoutIcon,
  MenuIcon,
  RiskProfilingIcon,
  TransactionIcon,
} from "../../images/icons";
// import Logo from "../../images/unuh logo.svg";
import Logo from "../../images/unuh-logo.png";

const menuItems = [
  { to: "dashboard", icon: <DashboardIcon />, label: "Dashboard" },
  {
    to: "transactions",
    icon: <TransactionIcon />,
    label: "Transaction Details",
  },
  {
    to: "risk-profiling",
    icon: <RiskProfilingIcon />,
    label: "Risk Profiling",
  },
  { to: "certificates", icon: <CertificateIcon />, label: "Certification" },
  { to: "content", icon: <ContentsIcon />, label: "Contents" },
  {
    to: "demographic-data",
    icon: <DemographicDataIcon />,
    label: "Demographic Data",
  },
  {
    to: "change-admin",
    icon: <ChangeAdminIcon />,
    label: "Change Admin",
  },
  {
    to: "data-management",
    icon: <DataManagement />,
    label: "Data Management",
  },
];

const AdminSideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleToggleSidebar = () => {
    setIsSidebarOpen((p) => !p);
  };
  useKeyboardShortcut(handleToggleSidebar, menuItems);

  return (
    <Box
      // className="hidescrollbar"
      sx={{
        boxShadow: "4px 0px 13px rgba(0, 0, 0, 0.12)",
        backgroundColor: (theme) => theme.palette.background.secondary,
        height: "100vh",
        position: "sticky",
        zIndex: 999,
        top: "0px",
        left: "0px",
        pl: isSidebarOpen ? "30px" : "10px",
        width: isSidebarOpen ? "200px" : "65px",
        overflowY: "scroll",
        transition: "all 0.75s",
      }}
    >
      <Box
        sx={{
          mt: 2,
          mb: 4,
          height: "38px",
          width: isSidebarOpen ? "99px" : "40px",
          transition: "all 0.7s",
        }}
      >
        <img src={Logo} height="38px" />
      </Box>
      <Box sx={{ mb: 4 }}>
        <IconButton onClick={handleToggleSidebar}>
          <MenuIcon />
        </IconButton>
      </Box>

      <Box sx={{ ...menuItemsCSS }}>
        {menuItems.map((item, index) => (
          <NavLink
            key={index}
            to={item.to}
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box sx={{ ...flexBetweenWithItemsCenter, height: "40px" }}>
              <Box
                sx={{
                  ...sideNavbarLink,
                  ...textEllipses,
                }}
                title={item.label}
              >
                <IconButton sx={{ p: 0 }}>{item.icon} </IconButton>
                <span
                  style={{
                    opacity: isSidebarOpen ? 1 : 0,
                    transition: "opacity 0.5s ease-in-out",
                  }}
                >
                  {item.label}
                </span>
                {/* {isSidebarOpen && item.label} */}
              </Box>
              <Box
                sx={{
                  display: location.pathname.startsWith("/admin/" + item.to)
                    ? "block"
                    : "none",
                  justifySelf: "flex-end",
                  height: "100%",
                  border: "3px solid",
                  borderColor: (theme) => theme.palette.primary.main,
                  borderRadius: "50px 0 0 50px",
                }}
              ></Box>
            </Box>
          </NavLink>
        ))}
        <LogoutModal isSidebarOpen={isSidebarOpen} />
      </Box>
    </Box>
  );
};

export default AdminSideBar;

export function LogoutModal({ isSidebarOpen }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_LOGOUT;
      const config = createAxiosConfig({
        endPoint: url,
      });
      const apiRes = await axios.request(config);
      localStorage.clear("idToken");
      window.location.href = "/login";
      // navigate("/login");
    } catch (e) {
      console.log({ e });
      // toast.error(e?.response?.data);
      toast.error("Something went wrong");
    }
    setLoading(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleKeyPress = (e) => {
    if (e.altKey && e.shiftKey) {
      if (e.code == "KeyL") {
        handleOpen();
        // console.log("clicked");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          ...sideNavbarLink,
          color: (theme) => theme.palette.text.light,
          fill: (theme) => theme.palette.text.light,
          cursor: "pointer",
          ml: 0.5,
          height: "40px",
        }}
        onClick={handleOpen}
      >
        <IconButton sx={{ p: 0 }}>
          <LogoutIcon />
        </IconButton>
        <span
          style={{
            opacity: isSidebarOpen ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          Logout
        </span>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "515px", pb: "20px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent sx={{ mb: 2 }}>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to Logout?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              disabled={loading}
              onClick={handleLogout}
              variant="primary"
            >
              Logout
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
